@font-face {
	font-family: "Brandon Grotesque";
	font-weight: 400;
	src: url("../../../assets/fonts/Brandon_reg.otf");
}

@font-face {
	font-family: "Brandon Grotesque";
	font-weight: 500;
	src: url("../../../assets/fonts/Brandon_med.otf");
}

.main {
	height: 100%;
	overflow-y: scroll;
	width: 320px;
	z-index: 0;
}

/* Section */

.section {
	padding: 30px 30px 0 30px;
}

.sectionHeader {
	margin: 0 0 20px 0;
	width: 100%;
}

.sectionHeader h1 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 27px;
	font-weight: 400;
	margin: 0 0 10px 0;
}

.sectionHeaderContent {
	align-items: center;
	display: flex;
}

.sectionIcon {
	border-radius: 50%;
	height: 60px;
	width: 60px;
}

.sectionIconPlaceholder {
	align-items: center;
	background: #000000;
	border-radius: 50%;
	color: #FFFFFF;
	display: flex;
	font-size: 20px;
	height: 60px;
	justify-content: center;
	min-height: 60px;
	min-width: 60px;
}

.sectionHeaderDetails {
	margin: 0 0 0 15px;
}

.sectionHeaderDetails h2 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 18px;
	font-weight: 400;
	margin: 0;
	line-break: anywhere;
	line-height: 18px;
}

.callout {
	background: #FFFFFF;
	border-radius: 5px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	height: calc(180px);
	left: calc(-10px);
	padding: 15px 20px 10px 20px;
	position: absolute;
	top: 90px;
	// transform: translate(-50%, 0);
	width: calc(280px - 40px);
	z-index: 3;
}

.calloutContent {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.calloutNavigator {
	display: flex;
	justify-content: flex-end;
}

.calloutNavigator div:first-child {
	margin: 0 5px 0 0;
}

.activeNavigator, .inactiveNavigator {
	align-items: center;
	border-radius: 50%;
	display: flex;
	height: 25px;
	justify-content: center;
	width: 25px;
}

.activeNavigator {
	background: #267DFF;
	cursor: pointer;
}

.inactiveNavigator {
	background: #D9D9D9;
}

.navigatorArrow {
	width: 9px;
}

.callout .right {
	box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.1);
	background: #FFFFFF;
	border-radius: 3px;
	content: "\00a0";
    display: block;
    height: 24px;
	left: 32px;
    position: absolute;
    top: -10px;
    transform: rotate( 45deg );
    width: 24px;
	z-index: 5;
}

.calloutPersonPage {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 5px;
}

// .calloutPerson {

// }

.calloutPerson h1 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 16px;
	font-weight: 500;
	margin: 0 0 2px 0;
}

.calloutPerson h2 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin: 0;
}

.calloutLinkedIn {
	margin-right: 5px;
	width: 12px;
}

.stats {
	display: flex;
	justify-content: space-between;
	margin: 0 0 15px 0;
	position: relative;
}

.divider {
	background: #E7E7E7;
	height: 1px;
	width: 100%;
}

/* Sub-Section */

.subSection {
	margin: 20px 0 0 0;
	padding: 0 30px;
}

.subSection h1 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 25px;
	font-weight: 400;
	margin: 0 0 10px 0;
}

.editSubSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.editSubSection:hover #editButton{
    visibility: visible;
}

#editButton {
	background-color: #FFFFFF;
	border: none;
	color: transparent;
    text-shadow: 0 0 0 rgb(0, 0, 0);
	visibility: hidden;
	margin-top: 8px;
	margin-left: -2px;
	margin-right: -2px;
}

/* Tile List */

.tileList {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.tile {
	background: #000000;
	border-radius: 3px;
	color: #FFFFFF;
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin: 0 5px 5px 0;
	padding: 4px 10px;
}

/* Editing/Adding in sidebar */

.deleteTileList {
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
}

.deleteButton {
	background-color: #000000;
	border: none;
	color: transparent;
    text-shadow: 0 0 0 rgb(255, 255, 255);
	margin-right: -7px;
	margin-top: -3px;
}

.deleteTile {
	background: #000000;
	border-radius: 3px;
	display: flex;
	align-items: center;
	color: #FFFFFF;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin: 0 5px 5px 0;
	padding: 4px 10px;
}

.cancel {
	background: #000000;
	border: none;
	border-radius: 3px;
	color: #FFFFFF;
	font-family: "Brandon Grotesque", sans-serif;
	float: right;
}

/* Roles List */

.rolesList {
	display: flex;
	flex-direction: column;
}

.role, .school {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 15px;
	font-weight: 400;
	margin: 0 0 5px 0;
}

.role a {
	font-weight: 500;
	text-decoration: none;
}

.school a {
	color: #267DFF;
}

/* Person */

.person {
	height: 100%;
	width: 100%;
}

.personData:last-child {
	margin-bottom: 30px;
}

/* Loading */

.loading {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
}
