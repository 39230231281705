@font-face {
	font-family: "Brandon Grotesque";
	font-weight: 400;
	src: url("../../../assets/fonts/Brandon_reg.otf");
}

.wall{
	background: url("../../../assets/images/hyperspace.jpeg");
	background-size: cover;
}

.container {
	align-items: center;
	animation: saturation 4.2s linear;
	background-color: #000;
	display: flex;
	height: 100vh;
	justify-content: center;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.content {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.content h1 {
	color: #FFF;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 30px;
	letter-spacing: 5px;
	margin: 15px 0 0 0;
	text-transform: uppercase;
}

.welcome {
	align-items: center;
	animation: zoom 4s linear;
	display: flex;
	flex-direction: column;
	justify-content: center;
	opacity: 0;
}

.screen {
	background-color: #BADA55;
	height: 100vh;
	left: 0;
	mix-blend-mode: color-burn;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.scene{
	background: #000;
	display: inline-block;
	vertical-align: middle;
	perspective: 5px;
	perspective-origin: 50% 50%;
	position: relative;
}

.wrap{
	position: absolute;
	width: 1000px;
	height: 1000px;
	left: -500px;
	top: -500px;
	transform-style: preserve-3d;
	animation: move 12s infinite linear;
	animation-fill-mode: forwards;
	
}

.wrap:nth-child(2){
	animation: move 12s infinite linear;
	animation-delay: 6s;
}

.wall {
	width: 100%;
	height: 100%;
	position: absolute;
	opacity: 0;
	animation: fade 12s infinite linear;
	animation-delay: 0;
}

.wrap:nth-child(2) .wall {
	animation-delay: 6s;
}

.wallright {
	transform: rotateY(90deg) translateZ(500px);
}

.wallleft {
	transform: rotateY(-90deg) translateZ(500px);
}

.walltop {
	transform: rotateX(90deg) translateZ(500px);
}

.wallbottom {
	transform: rotateX(-90deg) translateZ(500px);
}

.wallback {
	transform: rotateX(180deg) translateZ(500px);
}

@keyframes move {
	0%{
		transform: translateZ(-500px) rotate(0deg);
	}
	100%{
		transform: translateZ(500px) rotate(0deg);
	}
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes zoom {
	0% {
		opacity: 0;
		transform: scale(0%);
	}
	30% {
		opacity: 0;
		transform: scale(0%);
	}
	40% {
		opacity: 1;
		transform: scale(100%);
	}
	70% {
		opacity: 1;
		transform: scale(100%);
	}
	90% {
		opacity: 1;
		transform: scale(10000%);
	}
	100% {
		opacity: 1;
		transform: scale(10000%);
	}
}

@keyframes saturation {
	0% {
		// opacity: 0;
		// transform: scale(0%);
	}
	30% {
		// opacity: 0;
		// transform: scale(0%);
	}
	40% {
		// opacity: 1;
		// transform: scale(100%);
	}
	70% {
		background-color: #000;
		filter: saturate(1);
		opacity: 1;
	}
	90% {
		filter: saturate(0);
	}
	100% {
		filter: saturate(0);
		opacity: 0;
		background-color: #FFF;
	}
}