.main {
	align-items: center;
	border-left: 1px solid #d1d1d1;
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
}

.loading {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 35px;
	font-weight: 400;
}

.rankable {
	max-width: 90%;
	width: 700px;
}

.person {
	align-items: center;
	display: flex;
}

.photo {
	background-size: cover;
	border-radius: 50%;
	height: 100px;
	margin: 0 20px 0 0;
	width: 100px;
}

.info {
	
	display: flex;
	flex-direction: column;
}

.header {
	align-items: center;
	display: flex;
}

.header a {
	height: 25px;
	margin: 0 0 0 15px;
}

.rankable h1 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 35px;
	font-weight: 500;
	margin: 0;
}

.rankable h2 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 25px;
	font-weight: 400;
	margin: 0;
}

.linkedIn {
	// color: #267DFF;
	// font-family: "Brandon Grotesque", sans-serif;
	// font-size: 20px;
	// font-weight: 400;
	// padding: 5px 0 10px 0;
	// text-decoration: none;
	height: 25px;
	opacity: 0.75;
	width: 25px;
}

.linkedIn:hover {
	opacity: 1;
}

.controls {
	display: flex;
	margin: 15px 0 0 0;
}

.controls button {
	align-items: center;
	border: none;
	border-radius: 5px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 20px;
	font-weight: 400;
	outline: none;
	padding: 7px 10px;
}

.controls button:hover {
	box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.pass {
	background: #DEDEDE;
	margin: 0 15px 0 0;
}

.good {
	background: #C5FFD5;
	margin: 0 15px 0 0;
}

.back {
	background: #F5BF4F;
}

.controls .key {
	align-items: center;
	background: #F5F5F5;
	border-radius: 3px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 17px;
	font-weight: 500;
	height: 35px;
	justify-content: center;
	margin: 0 10px 0 0;
	width: 35px;
}