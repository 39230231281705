.MuiDataGrid-root {
	border-radius: 0 !important;
	box-sizing: content-box !important;
	width: 100%;
}

.MuiDataGrid-root .MuiDataGrid-cell {
	border-bottom: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-pinnedColumns {
	box-shadow: none !important;
}

.MuiDataGrid-pinnedColumnHeaders {
	/* border-bottom: 1px solid rgba(224, 224, 224, 1); */
	box-shadow: none !important;
}

.MuiDataGrid-row {
	cursor: pointer;
}