.searchBar {
	flex: 1;
	margin: 12px 23px;
	border: 1px solid #D4D4D4;
	border-radius: 5px;
	padding: 0 15px;
}

.inputWithButton {
	position: relative;
	border-left: 1px solid rgba(224, 224, 224, 1);
	border-right: 1px solid rgba(224, 224, 224, 1);
	display: flex;
	width: 100%;
}
	  
.inputWithButton input{
	font-size: 17px;
	width: 70%;
	height: 25px;
	padding-right: 60px;
	padding-top: 3px;
	padding-bottom: 3px;
}
	  
#clear {
	font-size: 17px;
	position: absolute;
	right: 25px;
	top : 14px;
	background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
	opacity: 0.5;
    outline:none;
}

.inputWithButton button:hover {
	opacity: 1;
}