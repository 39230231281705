.circle {
	align-items: center;
	display: flex;
	flex-direction: column;
	// position: relative;
}

.circle h4 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin: 5px 0 0 0;
	text-transform: uppercase;
}

.signal {
	align-items: center;
	display: flex;
}

.signal h1 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 18px;
	font-weight: 400;
}

.signalColor {
	border-radius: 50%;
	height: 16px;
	margin: 0 13px 0 0;
	width: 16px;
}

.connections {
	align-items: center;
	display: flex;
	position: relative;
}

.connection {
	align-items: center;
	border: 3.5px solid #FFFFFF;
	border-radius: 50%;
	color: #FFFFFF;
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 500;
	height: 30px;
	justify-content: center;
	line-height: 13px;
	position: absolute;
	width: 30px;
}

.connectionPlaceholder {
	align-items: center;
	background: #d4d4d4;
	background-size: cover;
	border: 3.5px solid #FFFFFF;
	border-radius: 50%;
	color: #FFFFFF;
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 500;
	height: 30px;
	justify-content: center;
	line-height: 13px;
	position: absolute;
	width: 30px;
}

.connectionAdditional {
	align-items: center;
	background: #C5FFD5;
	border: 3.5px solid #FFFFFF;
	border-radius: 50%;
	color: #2E8948;
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 13px;
	font-weight: 500;
	height: 30px;
	justify-content: center;
	line-height: 13px;
	position: absolute;
	width: 30px;
}