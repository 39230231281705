.main {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 0;
	width: 75px;
}

.loginCircle {
	background: gray;
	border-radius: 25px;
	height: 50px;
	width: 50px;
}

.navBarItem {
	align-items: center;
	display: flex;
	// border-radius: 50px;
	height: 50px;
	justify-content: center;
	margin: 0 0 10px 0;
	opacity: 0.25;
	transition: opacity 0.15s ease-in;
	width: 50px;
}

.navBarItem:hover {
	opacity: 1;
}

.navBarItem img {
	height: 35px;
}