.main {
	align-items: center;
	display: flex;
	flex: 1;
	height: 100vh;
	justify-content: center;
}

.main h1 {
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 35px;
	font-weight: 400;
	margin: 0 0 10px 0;
}

.content {
	align-items: center;
	display: flex;
	flex-direction: column;
}

.button {
	background: transparent;
	border: 2px solid #000000;
	border-radius: 5px;
	color: #000000;
	cursor: pointer;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 20px;
	font-weight: 300;
	padding: 10px 50px;
	transition: background-color 0.3s ease-in;
}

.button:hover {
	background: #000000;
	color: #FFFFFF;
}