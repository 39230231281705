@font-face {
	font-family: "Brandon Grotesque";
	font-weight: 400;
	src: url("../../../assets/fonts/Brandon_reg.otf");
}

.main {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
}

.columnPerson {
	align-items: center;
	display: flex;
}

.columnPersonPhoto {
	border-radius: 50%;
	height: 45px;
	margin: 0 10px 0 0;
	width: 45px;
}

.columnPersonPhotoPlaceholder {
	align-items: center;
	background: #F0F0F0;
	border-radius: 50%;
	color:#A0A0A0;
	display: flex;
	font-family: "Brandon Grotesque", sans-serif;
	font-size: 15px;
	font-weight: 400;
	height: 45px;
	justify-content: center;
	margin: 0 10px 0 0;
	width: 45px;
}

.columnPersonText {
	display: flex;
	flex-direction: column;
}

.columnPersonText h1 {
	font-size: 15px;
	margin: 0;
}

.columnPersonText h2 {
	color: #A0A0A0;
	font-size: 13px;
	font-weight: 400;
	margin: 0;
}

.columnPrograms {
	display: flex;
	overflow: scroll;
	width: 100%;
}

.columnPrograms::-webkit-scrollbar {
	display: none;
}

.columnProgramsItem {
	background: #000000;
	border-radius: 3px;
	color: #FFFFFF;
	font-size: 13px;
	margin: 0 5px 0 0;
	padding: 2px 7px;
}

.columnContacts {
	align-items: center;
	display: flex;
}

.linkedIn {
	width: 20px;
}

.linkedIn:hover {
	opacity: 0.5;
}

.noResults {
	text-align: center;
	font-size: 24px;
	margin-top: 100px;
}
